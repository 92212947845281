import cx from 'classnames'
import { motion } from 'framer-motion'
import { ReactNode } from 'react'

export default function Background ({
  children
}: {
  children?: ReactNode
}): JSX.Element {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
      className={cx(
        'min-h-screen bg-gradient-to-b from-green-900/[1%] to-green-900/[8%]',
        'relative z-0'
      )}
    >
      {children}
    </motion.div>
  )
}
